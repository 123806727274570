import Image from 'next/image';

import { TextImageBlockType } from '@humanoids/services/graphql/sdk';
import { Heading, parseMarkdown } from '@humanoids/ui';

import * as Styled from './TextImageBlock.styled';

export const TextImageBlock = ({
  heading,
  image,
  position,
  text,
}: TextImageBlockType) => (
  <Styled.Container>
    <Heading tertiary>{heading}</Heading>
    <Styled.Text
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(text),
      }}
      $large
    />
    <Styled.Image position={position}>
      <Image
        alt={image.alt}
        blurDataURL={image.asset.metadata.lqip}
        fill
        placeholder="blur"
        quality={85}
        sizes={Styled.imageMediaCondition}
        src={image.asset.url}
      />
    </Styled.Image>
  </Styled.Container>
);
