import Image from 'next/image';
import Link from 'next/link';

import { Badge, Text } from '@humanoids/ui';
import { CaseBlockType, PageType } from '@humanoids/services/graphql/sdk';
import { RichContent } from '@/components/rich-content';
import { useGeneratePath } from '@/hooks/use-generate-path';

import * as Styled from './CaseBlock.styled';

interface CaseBlockProps extends CaseBlockType {
  page: PageType;
}

export const CaseBlock = ({
  contentRaw,
  page,
  services,
  tools,
}: CaseBlockProps) => {
  const generatePath = useGeneratePath();

  return (
    <>
      <Styled.Header>
        <Styled.Image>
          <Image
            alt={page.image?.alt}
            blurDataURL={page.image?.asset.metadata.lqip}
            fill
            placeholder="blur"
            quality={85}
            priority
            sizes={Styled.imageMediaCondition}
            src={page.image?.asset?.url}
          />
        </Styled.Image>
        <Styled.Overlay>
          <Text $large>{page.client}</Text>
          <Styled.LineDoodle />
          <Text $small>{page.subtitle}</Text>
        </Styled.Overlay>
        <Styled.Container>
          <Styled.Heading>{page.title}</Styled.Heading>
          <Styled.Text $extraLarge>{page.description}</Styled.Text>
          <Styled.Badges>
            {/* TODO: Remove nullish coalescing once validators have landed in CMS for 'services' and 'tools' */}
            {(services ?? []).map(({ _id, title, ...page }) => (
              <Link href={generatePath(page)} key={_id}>
                <Badge secondary>{title}</Badge>
              </Link>
            ))}
            {(tools ?? []).map(({ _id, title, ...page }) => (
              <Link href={generatePath(page)} key={_id}>
                <Badge>{title}</Badge>
              </Link>
            ))}
          </Styled.Badges>
        </Styled.Container>
      </Styled.Header>
      <Styled.RichContent>
        <RichContent contentRaw={contentRaw} />
      </Styled.RichContent>
    </>
  );
};
