import Image from 'next/image';

import { ImagesBlockType } from '@humanoids/services/graphql/sdk';
import { Heading, Text } from '@humanoids/ui';

import * as Styled from './ImagesBlock.styled';

export const ImagesBlock = ({ images }: ImagesBlockType) => (
  <Styled.Container>
    {images.map(({ _key, description, image, title }) => (
      <Styled.ImageWrapper key={_key}>
        <Styled.Image>
          <Image
            alt={image.alt}
            blurDataURL={image.asset.metadata.lqip}
            fill
            placeholder="blur"
            quality={85}
            sizes={Styled.imageMediaCondition}
            src={image.asset.url}
          />
        </Styled.Image>
        <Styled.TextWrapper>
          {title && (
            <Heading as="h3" quinary>
              {title}
            </Heading>
          )}
          {description && <Text $small>{description}</Text>}
        </Styled.TextWrapper>
      </Styled.ImageWrapper>
    ))}
  </Styled.Container>
);
