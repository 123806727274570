import Image from 'next/image';

import { PicturesBlockType } from '@humanoids/services/graphql/sdk';

import * as Styled from './PicturesBlock.styled';

export const PicturesBlock = ({ pictures }: PicturesBlockType) => (
  <Styled.Container fullWidth={pictures.length > 1}>
    {pictures.map(({ _key, originalAspectRatio, picture }) => (
      <Styled.Picture
        key={_key}
        $height={picture.asset.metadata.dimensions.height}
        originalAspectRatio={originalAspectRatio}
        $width={picture.asset.metadata.dimensions.width}
      >
        <Image
          alt={picture.alt}
          blurDataURL={picture.asset.metadata.lqip}
          fill
          placeholder="blur"
          quality={85}
          sizes={
            pictures.length > 1
              ? Styled.imageMediaConditionMultiple
              : Styled.imageMediaCondition
          }
          src={picture.asset.url}
        />
      </Styled.Picture>
    ))}
  </Styled.Container>
);
