import { Text, parseMarkdown } from '@humanoids/ui';
import { TextBlockType } from '@humanoids/services/graphql/sdk';

import * as Styled from './TextBlock.styled';

export const TextBlock = ({
  align,
  size,
  standalone,
  text,
  ...props
}: TextBlockType) => (
  <Styled.Container align={align} standalone={standalone}>
    <Text
      $align={align}
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(text),
      }}
      $small={size === 'small'}
      $regular={size === 'regular'}
      $large={size === 'large'}
      $extraLarge={size === 'extraLarge'}
      $extraExtraLarge={size === 'extraExtraLarge'}
      {...props}
    />
  </Styled.Container>
);
