import { useRef, useState } from 'react';
import { Text, parseMarkdown } from '@humanoids/ui';

import { AccordionBlockType } from '@humanoids/services/graphql/sdk';

import * as Styled from './AccordionBlock.styled';

export const AccordionBlock = ({ accordionItems }: AccordionBlockType) => {
  const contentRefs = useRef<{ [_key: string]: HTMLDivElement }>({});
  const [visibleItem, setVisibleItem] = useState<string | undefined>();

  const toggleItem = (_key: string) => {
    setVisibleItem(_key === visibleItem ? null : _key);
  };

  return (
    <Styled.Container>
      {accordionItems.map(({ _key, subtitle, text, title }) => (
        <Styled.AccordionItem key={_key} onClick={() => toggleItem(_key)}>
          {subtitle && <Styled.Subtitle as="h3">{subtitle}</Styled.Subtitle>}
          <Styled.Title as="h4">{title}</Styled.Title>
          <Styled.Chevron direction={visibleItem === _key ? 'up' : 'down'} />
          <Styled.Content
            _key={_key}
            contentRefs={contentRefs.current}
            ref={(element) => {
              contentRefs.current[_key] = element;
            }}
            visible={visibleItem === _key}
          >
            <Styled.Line />
            <Text
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(text),
              }}
            />
          </Styled.Content>
        </Styled.AccordionItem>
      ))}
    </Styled.Container>
  );
};
