import Image from 'next/image';
import {
  PortableText,
  PortableTextComponents,
  PortableTextReactComponents,
} from '@portabletext/react';
import { TypedObject } from 'sanity';

import { Code, Heading, Text } from '@humanoids/ui';

import { fileUrlBuilder, imageUrlBuilder } from '@/services';

import * as Styled from './RichContent.styled';

const components: PortableTextComponents = {
  types: {
    code: ({ value }) => <Code code={value.code} language={value.language} />,
    inlineImage: ({ value }) => (
      <Styled.Figure className={value.position}>
        <Styled.Image>
          <Image
            alt={value.alt}
            fill
            sizes={Styled.imageMediaCondition}
            src={imageUrlBuilder.image(value.asset._ref).url()}
            {...(!value.preserveAspectRatio ? { quality: 85 } : {})}
          />
        </Styled.Image>
        {value.caption && <Styled.Caption>{value.caption}</Styled.Caption>}
      </Styled.Figure>
    ),
    video: ({ value }) => (
      <>
        <Styled.Video
          controls
          playsInline
          poster={
            value.poster?.asset._ref &&
            imageUrlBuilder.image(value.poster.asset._ref).url()
          }
        >
          <source
            src={`${fileUrlBuilder.file(value.asset._ref)}`}
            type="video/mp4"
          />
        </Styled.Video>
        {value.caption && (
          <Styled.VideoCaption>{value.caption}</Styled.VideoCaption>
        )}
      </>
    ),
  },

  block: {
    h2: ({ children }) =>
      children !== '' ? <Heading secondary>{children}</Heading> : null,

    h3: ({ children }) =>
      children !== '' ? <Heading tertiary>{children}</Heading> : null,

    h4: ({ children }) =>
      children !== '' ? <Heading quaternary>{children}</Heading> : null,

    normal: ({ children }) =>
      children !== '' ? <Text $large>{children}</Text> : null,

    blockquote: ({ children }) =>
      children !== '' ? (
        <Text $large style={{ fontStyle: 'italic' }}>
          {`"${children}"`}
        </Text>
      ) : null,
  },

  marks: {
    em: ({ children }) => <em>{children}</em>,
    i: ({ children }) => <i>{children}</i>,
    link: ({ children, value }) => (
      <a
        href={value.href}
        rel={
          !value.href.startsWith('/') ? 'noreferrer nofollow noopener' : null
        }
        target={!value.href.startsWith('/') ? '_blank' : ''}
      >
        {children}
      </a>
    ),
  },
};

interface RichContentProps {
  contentRaw: TypedObject | TypedObject[];
}

export const RichContent = ({ contentRaw }: RichContentProps) => (
  <Styled.RichText>
    <PortableText
      components={components as unknown as Partial<PortableTextReactComponents>}
      value={contentRaw}
    />
  </Styled.RichText>
);
