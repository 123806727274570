import { gql } from 'graphql-request';
import { useIntl } from 'react-intl';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'next/image';

import { ContactBlockType } from '@humanoids/services/graphql/sdk';
import { Heading, Text, parseMarkdown, theme } from '@humanoids/ui';
import { imageUrlBuilder } from '@/services';
import { useMediaQuery } from '@/hooks/use-media-query';

import * as Styled from './ContactBlock.styled';

export const contactBlockFragment = gql`
  fragment contactBlock on ContactBlock {
    __typename
    _key
    _type
    person {
      avatar {
        asset {
          url
          metadata {
            lqip
          }
        }
        crop {
          bottom
          left
          right
          top
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      emailAddress
      linkedIn
      name
      phoneNumber
      position
    }
    title
  }
`;

export const ContactBlock = ({ person, title }: ContactBlockType) => {
  const intl = useIntl();
  const pathname = usePathname();
  const router = useRouter();

  const desktop = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);

  return (
    <Styled.Background>
      {desktop && <Styled.Heading tertiary>{title}</Styled.Heading>}
      <Styled.Container>
        <Styled.StripesDoodleImage>
          <Image alt="stripes doodle" fill src="/images/stripes-doodle.svg" />
        </Styled.StripesDoodleImage>
        <Styled.FireworksDoodleImage>
          <Image
            alt="fireworks doodle"
            fill
            src="/images/fireworks-doodle.svg"
          />
        </Styled.FireworksDoodleImage>
        <Styled.Avatar>
          <Image
            alt={person.name}
            blurDataURL={person.avatar.asset.metadata.lqip}
            fill
            placeholder="blur"
            quality={85}
            sizes={Styled.imageMediaCondition}
            src={imageUrlBuilder.image(person.avatar).size(664, 664).url()}
          />
        </Styled.Avatar>
        <Styled.TextContainer>
          {!desktop && <Heading tertiary>{title}</Heading>}
          {desktop && (
            <Text $large>
              {intl.formatMessage({
                id: 'contactUs',
              })}
            </Text>
          )}
          <Text>
            <b>{person.name}</b>
            <br />
            {person.position}
          </Text>
          <Styled.ContactDetails>
            <Styled.Text $small>
              {intl.formatMessage({
                id: 'phoneNumber',
              })}
              <a href={`tel:${person.phoneNumber}`}>{person.phoneNumber}</a>
            </Styled.Text>
            <Styled.Text $small>
              {intl.formatMessage({
                id: 'emailAddress',
              })}
              <a href={`mailto:${person.emailAddress}`}>
                {person.emailAddress}
              </a>
            </Styled.Text>
            <Styled.Text $small>
              {intl.formatMessage({
                id: 'linkedIn',
              })}
              <a
                href={`https://${person.linkedIn}`}
                rel="noreferrer"
                target="_blank"
              >
                {person.linkedIn}
              </a>
            </Styled.Text>
          </Styled.ContactDetails>
        </Styled.TextContainer>
        <Styled.StartInquiry>
          <Text $large>
            {intl.formatMessage({
              id: 'inquiryCtaButtonDesktop',
            })}
          </Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(
                intl.formatMessage({ id: 'contactBlockInquiry' })
              ),
            }}
            $small
          />
          <Styled.Button
            onClick={() =>
              router.push(`${pathname}?inquiry=true`, { scroll: false })
            }
            tertiary
          >
            {intl.formatMessage({
              id: 'inquiryCtaButtonDesktop',
            })}
          </Styled.Button>
        </Styled.StartInquiry>
      </Styled.Container>
    </Styled.Background>
  );
};
