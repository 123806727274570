import { useContext } from 'react';
import Image from 'next/image';

import { ArticlesLatestBlockType } from '@humanoids/services/graphql/sdk';
import { Author, Text } from '@humanoids/ui';
import { imageUrlBuilder } from '@/services';
import { useGeneratePath } from '@/hooks/use-generate-path';

import { StoreContext } from '../store-provider';
import * as Styled from './ArticlesLatestBlock.styled';

export const ArticlesLatestBlock = ({ limit }: ArticlesLatestBlockType) => {
  const [{ articles }] = useContext(StoreContext);
  const generatePath = useGeneratePath();

  return (
    <Styled.Container>
      {articles
        .slice(0, limit)
        .map(({ _id, author, image, title, ...page }) => (
          <Styled.Link href={generatePath(page)} key={_id}>
            <Styled.Image>
              <Image
                alt={image.alt}
                blurDataURL={image.asset.metadata.lqip}
                fill
                placeholder="blur"
                quality={85}
                sizes={Styled.imageMediaCondition}
                src={image.asset.url}
              />
            </Styled.Image>
            <Styled.Card size="small">
              <Text>{title}</Text>
              <Author
                avatar={imageUrlBuilder.image(author.avatar).size(64, 64).url()}
                name={author.name}
              />
            </Styled.Card>
          </Styled.Link>
        ))}
    </Styled.Container>
  );
};
