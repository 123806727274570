import { Author, Badge, Heading } from '@humanoids/ui';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/compat/router';
import Image from 'next/image';

import { ArticleBlockType, PageType } from '@humanoids/services/graphql/sdk';
import { imageUrlBuilder } from '@/services';
import { useGeneratePath } from '@/hooks/use-generate-path';

import { RichContent } from '../rich-content';
import { StoreContext } from '../store-provider';
import * as Styled from './ArticleBlock.styled';

interface ArticleBlockProps extends ArticleBlockType {
  page: PageType;
}

import { gql } from 'graphql-request';

export const articleBlockFragment = gql`
  fragment articleBlock on ArticleBlock {
    __typename
    _key
    contentRaw
    tags
  }
`;

export const ArticleBlock = ({ contentRaw, page, tags }: ArticleBlockProps) => {
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const router = useRouter();
  const [{ articles }] = useContext(StoreContext);
  const generatePath = useGeneratePath();
  const intl = useIntl();

  return (
    <>
      <Styled.Header>
        <Styled.HeaderContainer>
          <Styled.Wrapper>
            <Styled.Date>
              {new Date(
                page.latestUpdateDateTime || page.publicationDateTime
              ).toLocaleDateString(router.locale, dateFormat)}
            </Styled.Date>
            <Heading secondary>{page.title}</Heading>
            <Author
              avatar={imageUrlBuilder
                .image(page.author.avatar)
                .size(64, 64)
                .url()}
              name={page.author.name}
              position={page.author.position}
            />
          </Styled.Wrapper>
          <Styled.Image>
            <Image
              alt={page.image.alt}
              blurDataURL={page.image.asset.metadata.lqip}
              fill
              placeholder="blur"
              quality={85}
              priority
              sizes={Styled.imageMediaCondition}
              src={page.image.asset.url}
            />
          </Styled.Image>
        </Styled.HeaderContainer>
      </Styled.Header>
      <Styled.Container>
        <RichContent contentRaw={contentRaw} />
        <Styled.Aside>
          {/* TODO: Make this an actual article filter */}
          <Heading quaternary as="h3">
            Tags
          </Heading>
          <Styled.Tags>
            {tags?.map((tag) => <Badge key={tag}>{tag}</Badge>)}
          </Styled.Tags>
          <Heading quaternary>
            {intl.formatMessage({
              id: 'latestArticles',
            })}
          </Heading>
          {articles.slice(0, 3).map(({ _id, author, title, ...page }) => (
            <Styled.ArticleLink href={generatePath(page)} key={_id}>
              <Styled.ArticleTitle>{title}</Styled.ArticleTitle>
              <Author
                avatar={imageUrlBuilder.image(author.avatar).size(64, 64).url()}
                name={author.name}
              />
            </Styled.ArticleLink>
          ))}
        </Styled.Aside>
      </Styled.Container>
    </>
  );
};
