import React from 'react';
import Head from 'next/head';

import { PageType } from '@humanoids/services/graphql/sdk';
import { useGeneratePath } from '@/hooks/use-generate-path';

interface OpenGraphProps {
  page: PageType;
}

export const OpenGraph = ({ page }: OpenGraphProps) => {
  const generatePath = useGeneratePath();

  const openGraphImageUrl = `${
    process.env.NEXT_PUBLIC_HOSTNAME
  }/api/og?title=${encodeURIComponent(page.title)}${
    page.description
      ? `&description=${encodeURIComponent(page.description)}`
      : ''
  }&coverImage=${
    page.image?.asset.url
      ? `${page.image?.asset.url}?h=630&w=1200&q=85`
      : `${process.env.NEXT_PUBLIC_HOSTNAME}/images/social-sharing.jpg`
  }`;

  return (
    <Head>
      <meta
        property="og:type"
        content={page.type === 'page' ? 'website' : 'article'}
      />
      <meta property="og:title" content={page.title} />
      <meta property="og:description" content={page.description} />
      <meta content={openGraphImageUrl} name="image" property="og:image" />
      {(page.type === 'article' || page.type === 'case') && (
        <meta property="og:image:secure_url" content={openGraphImageUrl} />
      )}
      {/* TODO: Change to multiple authors, not just one */}
      {page.type === 'article' && (
        <>
          <meta property="article:author" content={page.author.name} />
          <meta
            property="article:published_time"
            content={page.publicationDateTime}
          />
        </>
      )}
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_HOSTNAME}${generatePath(page)}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={page.title} />
      <meta name="twitter:description" content={page.description} />
      <meta name="twitter:image" content={openGraphImageUrl} />
      <meta
        name="twitter:url"
        content={`${process.env.NEXT_PUBLIC_HOSTNAME}${generatePath(page)}`}
      />
    </Head>
  );
};
