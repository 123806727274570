import Image from 'next/image';
import { gql } from 'graphql-request';

import { QuoteBlockType } from '@humanoids/services/graphql/sdk';
import { Text, parseMarkdown } from '@humanoids/ui';
import { imageUrlBuilder } from '@/services';

import * as Styled from './QuoteBlock.styled';

export const quoteBlockFragment = gql`
  fragment quoteBlock on QuoteBlock {
    __typename
    _key
    _type
    image {
      alt
      ...asset
    }
    person {
      avatar {
        asset {
          url
          metadata {
            lqip
          }
        }
        crop {
          bottom
          left
          right
          top
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      name
      position
    }
    quote
    video {
      asset {
        url
      }
    }
  }
`;

export const QuoteBlock = ({ image, person, quote, video }: QuoteBlockType) => (
  <Styled.Container as="blockquote">
    <Styled.Text
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(quote),
      }}
      $large={!image && !video}
    />
    {!person && <Styled.QuotationMarkIcon />}
    {person && (
      <Styled.Person>
        <Styled.Avatar>
          <Image
            alt={person.name}
            blurDataURL={person.avatar.asset.metadata.lqip}
            fill
            placeholder="blur"
            quality={85}
            sizes={Styled.avatarMediaCondition}
            src={imageUrlBuilder.image(person.avatar).url()}
          />
        </Styled.Avatar>
        <Text>
          <b>{person.name}</b>
          {person.position}
        </Text>
        <Styled.QuotationMarkIcon />
      </Styled.Person>
    )}
    {image?.asset && (
      <Styled.Image>
        <Image
          alt={image.alt}
          blurDataURL={image.asset.metadata.lqip}
          fill
          placeholder="blur"
          src={image.asset.url}
        />
      </Styled.Image>
    )}
    {video?.asset && (
      <Styled.Video>
        <video controls>
          <source src={video.asset.url} type="video/mp4" />
        </video>
      </Styled.Video>
    )}
  </Styled.Container>
);
